import Link from 'next/link'
import clsx from 'clsx'
import { Container } from '@ui/components/Container'
import { Logo } from '@ui/components/Svg'
import { SocialIcons } from '@ui/components/SocialIcons'

export function LandingFooter({
  className,
  isLoggedIn = false,
}: {
  className?: string
  isLoggedIn?: boolean
}) {
  return (
    <footer className={clsx('bg-blueberry-1000 text-white', className)}>
      <Container>
        <div className="flex flex-col items-center justify-between gap-8 py-12 lg:flex-row lg:gap-0 lg:py-20">
          <div className="flex w-full flex-grow flex-col gap-6 text-sm lg:w-2/3 lg:flex-1">
            <Logo />
            <div className="body-3 font-semibold">
              &copy; {new Date().getFullYear()} ATM.com
              <sup>&reg;</sup> | All rights reserved.
              <br />
              None of your data will ever be used without your permission.
            </div>
            <div className="border-blueberry-100 flex space-x-4 border-b pb-8 lg:border-b-0 lg:pb-0">
              <SocialIcons className="flex gap-5" />
            </div>
          </div>
          <div className="body-4 w-full font-bold lg:mb-8 lg:w-auto">
            <div className="grid grid-cols-2 items-center gap-4">
              <Link
                className="hover:text-blueberry-200 transition-colors"
                href="https://atm.com/privacy-policy/"
              >
                Privacy Policy
              </Link>
              <Link
                className="hover:text-blueberry-200 transition-colors"
                href="https://atm.com/terms-of-service/"
              >
                Terms of Service
              </Link>
              <Link
                className="hover:text-blueberry-200 transition-colors"
                href="https://atm.com/legal-disclaimers/"
              >
                Legal Disclaimers
              </Link>
              <Link
                className="hover:text-blueberry-200 transition-colors"
                href="https://atm.com/privacy-policy/#California-Privacy-Rights"
              >
                Do Not Sell My Personal Information
              </Link>
              {isLoggedIn && (
                <Link
                  className="hover:text-blueberry-200 transition-colors"
                  href="/auth/logout"
                >
                  Logout
                </Link>
              )}
            </div>
          </div>
        </div>
      </Container>
    </footer>
  )
}
